<template>
  <div class="modal" id="withdrawModal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="text-center mt-5" v-show="ui.loading === true">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="modal-header" v-show="ui.loading === false">
        <div>
          <h3>
            Withdraw Funds
          </h3>
          <span class="sub-title">
            Send withdrawal fund request
          </span>
        </div>
      </div>
      <div class="modal-body" id="modal-body-scroll" v-show="ui.loading === false">
        <div class="modal-body-container mt-3">
          <label for="availableBalance">
            Available Balance
          </label>
          <div id="availableBalance">
            $80,059.71
          </div>
        </div>
        <div class="modal-body-container mt-3">
          <label for="cardEnding">
            Withdrawal Card
          </label>
          <div id="cardEnding">
            Card Ending in 6645
          </div>
        </div>
        <div class="modal-body-container mt-3">
          <label for="setAmount">
            Amount
          </label>
          <div id="setAmount">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="fieldDefaultAmount" value="80,059.71" v-model="amount">
              <label class="form-check-label" for="fieldDefaultAmount">
                $80,059.71
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="fieldSetAmount" value="otherAmount" v-model="amount">
              <label class="form-check-label" for="fieldSetAmount">
                Other Amount
              </label>
            </div>
            <div class="form-group col-md-6 mt-3" v-if="amount === 'otherAmount'">
              <label for="fieldLoanAmount">Loan Amount</label>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">$</div>
                </div>
                <input type="number"
                       class="form-control prepend"
                       id="fieldLoanAmount" placeholder="320000" v-model="loan.amount">
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 modal-alert modal-alert_advice">
          You can request withdrawal only on Thursdays (next on Jun, 11 2020)
        </div>
        <div class="col-12 modal-alert modal-alert_success mt-3">
          Your withdrawal request successfully sent (on Jun, 11 2020 5:53pm)
        </div>
        <div class="col-12 modal-alert modal-alert_error mt-3">
          Sorry! We couldn’t sent withdrawal request. Please try later.
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start" v-show="ui.loading === false">
        <button type="submit" class="btn bg-primary submit" @click="saveWithdraw">
          <span v-if="ui.form.loading"
                class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span v-if="!ui.form.loading">Withdraw Funds</span>
        </button>
        <button type="reset" class="btn btn-cancel" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';

export default {
  name: 'WithdrawModal',
  data() {
    return {
      amount: 'otherAmount',
      loan: {
        amount: ''
      },
      ui: {
        loading: false,
        ps: null,
        form: {
          loading: false
        }
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;

      setTimeout(() => {
        this.ui.loading = false;
      }, 1500)
    },
    saveWithdraw() {
      this.ui.form.loading = true;

      setTimeout(() => {
        this.ui.form.loading = false;
      }, 1000)
    }
  },
  created() {
    this.getData();
  },
  mounted() {
    const scrollbar = document.getElementById('modal-body-scroll');
    this.ui.ps = new PerfectScrollbar(scrollbar, {
      useBothWheelAxes: false,
      suppressScrollX: true
    });
  },

}
</script>

<style lang="scss" scoped>
#modal-body-scroll {
  position: relative;
  overflow: hidden;
  height: calc(100% - 50px);
  width: 100%;
}
.form-check-input[type="radio"] {
  max-width: 18px;
  height: 18px;
  margin-bottom: 0;
  margin-top: 0;
  &:checked {
    background-color: rgba(1,122,255,1);
    border-color: rgba(1,122,255,1);
  }
}
.form-check {
  margin: 0;
}
</style>
