<template>
  <div class="modal" id="transaction-details">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="text-center mt-5" v-show="ui.loading === true">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="modal-header" v-show="ui.loading === false">
        <div>
          <h3>
            Transaction Detail
          </h3>
          <div>
            <img src="@/assets/icons/icon-unverified.svg" class="d-none" alt="">
            <img src="@/assets/icons/icon-verified green.svg" alt="">
            <span>
              John Smith’s funding added by
            </span>
            <div class="d-inline text-decoration-underline">
              Nathan Kowarsky
            </div>
          </div>
        </div>
      </div>
      <div class="modal-body" id="modal-body-scroll" v-show="ui.loading === false">
        <div class="modal-body-container mt-3 border-0 pb-0">
          <div class="table">
            <table class="table">
              <tbody>
                <tr>
                  <td>Payment Type</td>
                  <td>Wire</td>
                </tr>
                <tr>
                  <td>Date of wire</td>
                  <td>Apr, 11 2020</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-body-container border-0 pb-0">
          <div class="modal-body-container_title">
            Transactions
          </div>
          <div class="table">
            <table class="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Amount</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Nathan Kowarsky
                  </td>
                  <td>
                    <span class="color-green">
                    + $2400.00
                    </span>
                  </td>
                  <td>
                    John Smith Funding
                  </td>
                </tr>
                <tr>
                  <td>
                    Nathan Kowarsky
                  </td>
                  <td>
                    <span class="color-green">
                    + $2400.00
                    </span>
                  </td>
                  <td>
                    John Smith Funding
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-body-container border-0">
          <div class="modal-body-container_title">
            Expenses
          </div>
          <div class="expense-added border-bottom-lg">
            No Expense added
          </div>
        </div>
        <div class="modal-body-container border-0 pb-0">
          <div class="modal-body-container_title">
            Request
          </div>
          <div class="table">
            <table class="table">
              <tbody>
                <tr>
                  <td>Funding request by</td>
                  <td>Nathan Kowarsky</td>
                </tr>
                <tr>
                  <td>Branch</td>
                  <td>Fairchild</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-body-container border-0 pb-0">
          <div class="modal-body-container_title">
            Operation Log
          </div>
          <div class="table operation-table">
            <table class="table">
              <thead>
                <tr>
                  <th>Timestamp</th>
                  <th>Author</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Jun, 03 2020 16:17</td>
                  <td>Nathan Kowarsky</td>
                  <td>Expense submitted</td>
                </tr>
                <tr>
                  <td>Jun, 03 2020 16:17</td>
                  <td>Nathan Kowarsky</td>
                  <td>Expense submitted</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-body-container border-0 pb-0">
          <div class="modal-body-container_title">
            Invoice
          </div>
          <div class="attach-file">
            <div class="">
              <img src="@/assets/icons/icon-attachment-18.svg" alt="Attach icon">
              <span class="file-name">test.pdf</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';

export default {
  name: 'TransactionDetailsModal',
  data() {
    return {
      ui: {
        loading: false,
        ps: null
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;

      setTimeout(() => {
        this.ui.loading = false;
      }, 1500)
    }
  },
  created() {
    this.getData();
  },
  mounted() {
    const scrollbar = document.getElementById('modal-body-scroll');
    this.ui.ps = new PerfectScrollbar(scrollbar, {
      useBothWheelAxes: false,
      suppressScrollX: true
    });
  },
}
</script>

<style lang="scss" scoped>
div.table {
  &.operation-table {
    table {
      tbody {
        tr {
          td {
            opacity: 0.7;
            color: rgba(0,0,0,1);
            font-size: 13px;
            letter-spacing: 0;
            line-height: 18px;
          }
        }
      }
    }
  }
  table {
    thead {
      tr {
        th {
          color: rgba(0,0,0,1);
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 21px;
          opacity: 1;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 10px 15px;
          &:first-child {
            opacity: 0.7;
            color: rgba(0,0,0,1);
            font-size: 13px;
            letter-spacing: 0;
            line-height: 18px;
          }
          &:last-child {
            color: rgba(0,0,0,1);
            font-size: 13px;
            letter-spacing: 0;
            line-height: 18px;
          }
        }
      }
    }
  }
}

.expense-added {
  color: rgba(0,0,0,.5);
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  padding: 10px 12px;
}

.color-green {
  color: #28a745!important;
}

</style>
