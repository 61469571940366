<template>
  <div v-click-outside="closeCard">
    <div id="card-modal" >
      <img src="@/assets/icons/Credit-Card-CMC-Symbol.svg" alt="" class="card-modal_bg-img">
      <span class="card-title">
        Credit Card
    </span>
      <div class="card-number">4645   3476   3408  6601</div>
      <div class="row card-info">
        <div class="col-6">
          <div class="row">
            <div class="col-3">
              <div class="card-info-text">
                Good
                <br>
                Thru
              </div>
            </div>
            <div class="col-5 my-auto">
              <div class="card-info-text_white">
                06/2024
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="d-flex justify-content-end">
            <div class="card-info-text">
              Security
              <br>
              Code
            </div>
            <div class="card-info-text_white secure ms-2" v-if="isSecure === false">
              . . .
            </div>
            <div class="card-info-text_white secure ms-2 my-auto" v-if="isSecure === true">
              324
            </div>
            <div class="ms-2 cursor-pointer" @click="isSecure = !isSecure">
              <img src="@/assets/icons/icon-field Show Password-icon-visibility-on.svg" alt="" v-if="isSecure === false">
              <img src="@/assets/icons/icon-field Show Password-icon-visibility-off.svg" alt="" v-if="isSecure === true">
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-10">
          <div class="card-owner">
            Nathan Kowarsky
          </div>
        </div>
        <div class="col-2 text-end">
          <img src="@/assets/icons/icon-visa.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardModal',
  data() {
    return {
      isSecure: false,
      configOutsideClick: {
        handler: this.closeCard,
        middleware: this.middleware,
        events: ["click"]
      }
    }
  },
  methods: {
    closeCard() {
      this.$emit('close');
    },
    middleware() {
      return true;
    }
  }
}
</script>

<style lang="scss">
#card-modal {
  position: relative;
  top: 0px;
  width: 428px;
  height: 270px;
  padding: 44px 42px 42px 42px;
  border-radius: 18px;
  background: linear-gradient(180deg, rgba(59,59,59,1) 0%, rgba(31,31,31,1) 100%);
  box-shadow: 0 32px 32px -30px rgba(0,0,0,0.3);
  height: 270px;
  z-index: 9999;
  .card-modal_bg-img {
    position: absolute;
    top: 0;
    right: 0;
  }
  .card-title {
    opacity: 0.7;
    color: rgba(255,255,255,1);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
  }
  .card-number {
    color: rgba(255,255,255,1);
    font-size: 24px;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 64px;
    white-space: pre;
    word-spacing: 10px;
  }
  .card-owner {
    color: rgba(255,255,255,1);
    font-family: Poppins;
    font-size: 21px;
    letter-spacing: 0;
    line-height: 14px;
  }
  .card-info {
    margin-top: 21px;
    &-text {
      opacity: 0.46;
      color: rgba(255,255,255,1);
      font-size: 9px;
      letter-spacing: 0;
      line-height: 10px;
      white-space: pre;
    }
    &-text_white {
      color: rgba(255,255,255,1);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 12px;
    }
  }
}
</style>
