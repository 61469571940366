<template>
  <div id="accountPage">
    <div class="page-header d-flex justify-content-between align-items-center">
      <h3 class="page-header-title">
        Accounts
      </h3>
      <div>
        <multiselect v-model="value"
                     label="title" track-by="title"
                     class="page-header__select"
                     :options="options"
                     :option-height="104"
                     :show-labels="false">
          <template slot="option" slot-scope="props"><img class="option__image" :src="props.option.img" >
            <div class="option__desc">
              <span class="option__title">
              {{ props.option.title }}
              </span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="page-content p-normal">
      <div class="text-center mt-5" v-if="ui.loading === true">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <div class="row" v-if="ui.loading === false">
        <div class="col-sm col-md-4 col-lg mt-4 mt-md-0">
          <div class="account-list__card account-list_card_light-blue ">
            <div class="account-list__card--title">
              $80,059.71
            </div>
            <div class="account-list__card--subtitle">
              Available Balance
            </div>
            <div class="row">
              <div class="col-8 pe-0">
                <button class="btn btn-primary btn-modal" @click="modals.withdrawModal = true">
                  Withdraw
                </button>
              </div>
              <div class="col-4 position-relative" @click="modals.cardModal = true">
                <button class="btn btn-control">
                  <img src="@/assets/icons/icon-credit-card.svg" alt="">
                </button>
                <card-modal class="position-absolute"
                            v-if="modals.cardModal === true"
                            @close="modals.cardModal = false" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm col-md-4 col-lg mt-4 mt-md-0">
          <div class="account-list__card account-list_card_light-green">
            <div class="account-list__card--title">
              $27.5 m
            </div>
            <div class="account-list__card--subtitle">
              Funds added this month
            </div>
            <div class="row">
              <div class="col-8 pe-0">
                <button class="btn bg-green btn-modal" @click="modals.addFunding = true">
                  Add Funding
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm col-md-4 col-lg mt-4 mt-md-0">
          <div class="account-list__card account-list_card_light-pink">
            <div class="account-list__card--title">
              $15,000
            </div>
            <div class="account-list__card--subtitle">
              Spent this month
            </div>
            <div class="row">
              <div class="col-8 pe-0">
                <button class="btn bg-red btn-modal" @click="modals.addExpense = true">
                  Add Expense
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm col-md-4 col-lg mt-4 mt-md-0">
          <div class="account-list__card account-list_card_light-purple">
            <div class="account-list__card--title">
              20
            </div>
            <div class="account-list__card--subtitle">
              Existing Vendors
            </div>
            <div class="row">
              <div class="col-8 pe-0">
                <button class="btn bg-purple btn-modal" @click="modals.addVendor = true">
                  Add Vendor
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm col-md-4 col-lg mt-4 mt-md-0">
          <div class="account-list__card account-list_card_light-yellow">
            <div class="account-list__card--title">
              3
            </div>
            <div class="account-list__card--subtitle">
              Tickets to resolve
            </div>
            <div class="row">
              <div class="col-8 pe-0">
                <button class="btn bg-yellow btn-modal">
                  Resolution Center
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4 mb-2" v-if="ui.loading === false">
        <div class="col-6 col-md-6">
          <div class="position-relative select-with-icon">
            <img src="@/assets/icons/icon-filter field.svg" alt="Filter">
            <multiselect v-model="tableFilterSelect" :options="tableFilterSelects"
                         :multiple="true" :close-on-select="false" :clear-on-select="false"
                         :deselectGroupLabel="''"
                         :deselectLabel="''"
                         :preserve-search="true" placeholder="Pick some" label="name" track-by="name"
                         :preselect-first="true">
              <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
            </multiselect>
          </div>
        </div>
        <div class="col-6 col-md-6 text-end">
          <date-range-picker
            ref="picker"
            v-model="dateRange"
            class="daterange-dashboard"
            opens="left"
            :auto-apply="true"
            :timePicker="false"
            :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }">
            <template v-slot:input="picker">
              {{ picker.startDate | date }} - {{ picker.endDate | date }}
              <img src="@/assets/icons/icon-calender.svg" class="position-absolute" alt="">
            </template>
          </date-range-picker>
        </div>
      </div>

      <div class="table mt-3" v-if="ui.loading === false">
        <table class="table">
          <thead>
            <tr>
              <th>Transaction</th>
              <th>Status</th>
              <th>Amount</th>
              <th>Balance</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span class="table-borrower-name" @click="modals.transactionDetails = true">
                  John Smith
                </span>
                <span>
                  Funding $225,000.00
                </span>
              </td>
              <td>
                <img src="@/assets/icons/icon-verified green.svg" alt="">
              </td>
              <td>
                <div class="color-green">
                  + $2400.00
                </div>
              </td>
              <td>
                <div>
                  $80,059.71
                </div>
              </td>
              <td>
                Jun, 04 2020 09:32 am
              </td>
              <td>
                <button class="btn bg-white-border" @click="modals.transactionDetails = true">
                  View Details
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <span class="table-borrower-name">
                  John Smith
                </span>
                <span>
                  Funding $225,000.00
                </span>
              </td>
              <td>
                <img src="@/assets/icons/icon-verified black.svg" alt="">
              </td>
              <td>
                <div class="color-red">
                  + $2400.00
                </div>
              </td>
              <td>
                <div>
                  $80,059.71
                </div>
              </td>
              <td>
                Jun, 04 2020 09:32 am
              </td>
              <td>
                <button class="btn bg-white-border" @click="modals.transactionDetails = true">
                  View Details
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <transition name="component-fade" mode="out-in">
      <withdraw-modal
        v-if="modals.withdrawModal === true"
        @close="modals.withdrawModal = false" />
    </transition>

    <transition name="component-fade" mode="out-in">
      <new-funding-modal v-if="modals.addFunding === true"
                         @close="modals.addFunding = false" />
    </transition>

    <transition name="component-fade" mode="out-in">
      <add-expenses-modal
        v-if="modals.addExpense === true"
        @openVendor="modals.addVendor = true"
        @close="modals.addExpense = false" />
    </transition>

    <transition name="component-fade" mode="out-in">
      <add-vendor-modal
        v-if="modals.addVendor === true"
        @close="modals.addVendor = false" />
    </transition>

    <transition name="component-fade" mode="out-in">
      <transaction-details-modal
        v-if="modals.transactionDetails === true"
        @close="modals.transactionDetails = false" />
    </transition>


  </div>
</template>

<script>
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import WithdrawModal from "./modals/WithdrawModal";
import AddExpensesModal from "./modals/AddExpensesModal";
import AddVendorModal from "./modals/AddVendorModal";
import Multiselect from 'vue-multiselect';
import TransactionDetailsModal from "./modals/TransactionDetailsModal";
import CardModal from "./modals/CardModal";
import NewFundingModal from "../fundingRequests/modals/NewFundingModal";

export default {
  name: "Accounts",
  components: {
    NewFundingModal, WithdrawModal, AddExpensesModal,
    AddVendorModal, Multiselect, DateRangePicker,
    TransactionDetailsModal, CardModal
  },
  data() {
    return {
      value: { title: 'Export csv', img: '' },
      dateRange: {
        startDate: '2019-12-10',
        endDate: '2019-05-02'
      },
      options: [
        { title: 'Export entire company', img: require('@/assets/icons/icon-export-expenses.svg') },
        { title: 'Export Funding (CSV)', img: require('@/assets/icons/icon-export-fundings.svg') },
        { title: 'Export Expenses (CSV)', img: require('@/assets/icons/icon-export-fundings.svg') },
      ],
      tableFilterSelect: '',
      tableFilterSelects: [
        { name: 'Vue.js', language: 'JavaScript' },
        { name: 'Adonis', language: 'JavaScript' },
        { name: 'Rails', language: 'Ruby' },
        { name: 'Sinatra', language: 'Ruby' },
        { name: 'Laravel', language: 'PHP' },
        { name: 'Phoenix', language: 'Elixir' }
      ],
      modals: {
        withdrawModal: false,
        addFunding: false,
        addExpense: false,
        addVendor: false,
        transactionDetails: false,
        cardModal: false
      },
      ui: {
        loading: false
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;

      setTimeout(() => {
        this.ui.loading = false;
      }, 1500)
    }
  },
  filters: {
    date(date) {
      return moment(String(date)).format('MM/DD/YYYY')
    }
  },
  beforeMount() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
#accountPage {
  .btn-primary {
    font-size: 12px;
    &:hover {
      background-color: #025ce2;
      border-color: #0257d5;
    }
  }

  .daterange-dashboard {
    .form-control {
      line-height: 21px;
    }
    .reportrange-text {
      border-radius: 8px;
      padding: 9px 10px!important;
      font-size: 12px;
    }
  }

  .account-list {
    &__card {
      width: 100%;
      padding: 18px;
      border-radius: 12px;

      &--title {
        color: rgba(0,0,0,1);
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 21px;
      }

      &--subtitle {
        color: rgba(0,0,0,1);
        font-size: 12px;
        letter-spacing: 0;
        line-height: 12px;
        margin-top: 12px;
      }

      button {
        &.btn-modal {
          width: 100%;
          &.bg-yellow {
            color: rgba(0,0,0,1);
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 14px;
          }
        }
        &.btn-control {
          border: 1px solid rgba(0,0,0, .2);
          border-radius: 8px;
        }
        margin-top: 15px;
      }

      &.account-list_card_light-blue {
        border: 1px solid rgba(1,122,255,0.3);
        background-color: rgba(1,122,255,0.05);
      }

      &.account-list_card_light-green {
        border: 1px solid rgba(0,215,104,0.4);
        background-color: rgba(0,215,104,0.05);
      }

      &.account-list_card_light-pink {
        border: 1px solid rgba(236,34,84,0.3);
        background-color: rgba(236,34,84,0.05);
      }

      &.account-list_card_light-purple {
        border: 1px solid rgba(104,114,255,0.3);
        border-radius: 12px;
        background-color: rgba(104,114,255,0.05);
      }

      &.account-list_card_light-yellow {
        border: 1px solid rgba(247,181,0,0.3);
        background-color: rgba(247,181,0,0.05);
      }
    }

  }

  .table {
    table {
      tbody {
        tr {
          vertical-align: middle;
        }
        span {
          opacity: 0.7;
          color: rgba(0,0,0,1);
          font-size: 13px;
          letter-spacing: 0;
          line-height: 24px;
          &.table-borrower-name {
            opacity: 1;
            transition: all .3s;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .bg-white-border {
    &:hover {
      color: rgba(0,0,0,.7);
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
      background-color: rgba(0,0,0,.1);
    }
  }

  .select-with-icon {
    img {
      position: absolute;
      top: 7px;
      left: 6px;
      z-index: 99;
      opacity: 0.46;
    }

  }
}
.page-header::v-deep .page-header__select {
  max-width: 190px;
  min-width: 190px;
  width: 100%;
  .multiselect__input {
    height: 36px;
    margin: 0;
    background: none;
  }
  .multiselect__tags {
    max-height: 36px;
    min-height: 36px;
    padding: 8px 33px 0 8px;
  }
  .multiselect__select {
    max-height: 36px;
    padding: 0 8px;
    &:before {
      top: 15%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }

  }
  .multiselect__single {
    font-size: 12px;
  }
  .multiselect__option {
    display: flex;
    font-size: 12px;
    align-items: center;
    &::after {
      display: none;
    }
    &--highlight {
      background: lightgrey;
      color: black;
    }
    img {
      margin-right: 5px;
    }
  }
}
.select-with-icon::v-deep .multiselect {
  min-height: 36px;
  max-height: 36px;
  min-width: 210px;
  max-width: 220px;
  width: fit-content;
  @media screen and (max-width: 568px) {
    max-width: 50%;
    min-width: 100%;
  }
  &.multiselect--active {
    .multiselect__tags {
      transition: all .3s;
      border: 1px solid rgba(38,111,229,0.5);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__tags {
    min-height: 36px;
    max-height: 36px;
    padding: 8px 30px 0 35px;
    transition: all .3s;
    &:hover {
      border: 1px solid rgba(38,111,229,0.2);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__select {
    min-height: 36px;
    max-height: 36px;
    &:before {
      top: 15%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }
  &__single {
    color: rgba(0, 0, 0, 0.46);
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }
  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all .3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }
  &__placeholder {
    padding: 0;
  }
  input {
    padding: 0;
    margin: -4px;
    background: none;
  }
}

</style>
